import React from 'react';
import Layout from '../components/Layout';
import usePosts from '../hooks/use-posts';
import PostPreview from '../components/PostPreview';
import CategoryMap from '../constants/CategoryMap';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Each page will use <> wrapper to get global styles.
export default () => {
  const posts = usePosts();
  const category = CategoryMap.keys.stores;

  return (
    <>
      <Layout
        jumbo={true}
        pageTitle="Christian Stores & Websites Reviews & Discussion"
        pageDescription="Reviews & Discussion about Christian stores and websites, from where to best buy Christian goods to where to get news to great sites to learn more about God."
      >
        <h1
          css={css`
            color: ${CategoryMap.colors.stores} !important;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 2rem;
            border-bottom: 2px solid ${CategoryMap.colors.stores};
          `}
        >
          <FontAwesomeIcon
            css={css`
              color: ${CategoryMap.colors.stores} !important;
              margin-right: 0.5rem;
            `}
            icon={CategoryMap.icons.stores}
            size={'sm'}
            width="28"
          />
          {CategoryMap.keys.stores}
        </h1>
        <ul
          css={css`
            display: -webkit-box;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            justify-content: center;
          `}
        >
          {posts.map(post => {
            if (post.category.toLowerCase() === category.toLowerCase()) {
              return <PostPreview key={post.slug} post={post}></PostPreview>;
            } else {
              return true;
            }
          })}
        </ul>
      </Layout>
    </>
  );
};
